import { LoaderFunctionArgs, redirect, redirectDocument } from "react-router-dom";

import { getFullUrl } from "@libs/utils/location";
import { getTokensForApi, getTokensForAuthCheck } from "@libs/auth/getTokens";
import { getApiClient } from "@libs/api/clients";
import { getQueryKey } from "@libs/utils/queries";
import { paths } from "utils/routing/paths";
import { isSupportIdentityToken } from "utils/auth";
import { PracticeRouterContext } from "router/types";
import { getPracticeActivityStorage } from "storage/activity";
import { headers } from "utils/headers";

export const loader =
  ({ storage, baseUrl, queryClient }: PracticeRouterContext) =>
  async ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const tokens = await getTokensForAuthCheck(storage.localStorage);
    const activityStorage = getPracticeActivityStorage(storage.localStorage);
    const returnUrl = getFullUrl(url);

    if (activityStorage.isRecentlyActive()) {
      if (!tokens.identity) {
        return redirectDocument(paths.signIn({ returnUrl }));
      }

      if (!tokens.account) {
        return redirectDocument(paths.selectAccount({ returnUrl }));
      }

      // API requests here are an optimization
      // so that app blocking API requests are made
      // in parallel with lazy loaded component code

      // The API calls should be kept in sync with
      // what is being called in CurrentUserProvider
      const apiClient = getApiClient({
        baseUrl,
        headers,
        onRequestTokens: () => getTokensForApi(storage.localStorage),
      });

      const practiceId = tokens.account.practiceId;
      const userId = tokens.account.userId;

      if (isSupportIdentityToken(tokens.identity)) {
        queryClient.prefetchQuery({
          queryKey: [getQueryKey("practices", "getSupportUser"), { practiceId, supportUserId: userId }],
          queryFn: () => apiClient.practices.getSupportUser(practiceId, userId),
        });
      } else {
        queryClient.prefetchQuery({
          queryKey: [
            getQueryKey("practices", "getEmployee"),
            { practiceId, employeeId: userId, includeRole: true },
          ],
          queryFn: () => apiClient.practices.getEmployee(practiceId, userId, { includeRole: true }),
        });
      }

      queryClient.prefetchQuery({
        queryKey: [getQueryKey("practices", "getPracticeInfoById"), { practiceId }],
        queryFn: () => apiClient.practices.getPracticeInfoById(practiceId),
      });

      queryClient.prefetchQuery({
        queryKey: [getQueryKey("practices", "getPracticeFeatureStates"), { practiceId }],
        queryFn: () => apiClient.practices.getPracticeFeatureStates(practiceId),
      });

      return {
        accountToken: tokens.account,
        email: tokens.identity.email,
        isSupportUser: isSupportIdentityToken(tokens.identity),
      };
    } else if (tokens.identity) {
      return redirect(paths.signOut({ returnUrl }));
    }

    return redirectDocument(paths.signIn({ returnUrl }));
  };

export type SignedInAppLoader = typeof loader;
