import { FormHTMLAttributes, forwardRef } from "react";

export type FormProps = Omit<FormHTMLAttributes<HTMLFormElement>, "action" | "method">;

export const Form = forwardRef<HTMLFormElement, FormProps>((props, ref) => {
  {
    /*  eslint-disable jsx-a11y/no-redundant-roles */
  }

  return <form ref={ref} {...props} action="/" method="post" noValidate role="form" />;
});
