import { FC, PropsWithChildren, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { FormFieldContainer } from "@libs/components/UI/FormFieldContainer";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { FormFieldDescription } from "@libs/components/UI/FormFieldDescription";

export interface FormFieldProps {
  label?: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  required?: boolean;
  className?: string;
  containerClassName?: string;
  inputClassName?: string;
  disabled?: boolean;
  error?: string;
  edit?: boolean;
  displayErrorMessage?: boolean;
  errorId?: string;
}

export const cxStyle = {
  label: "font-sansSemiBold pb-1 text-sm md:pb-2 md:text-xs",
};

export const FormField: React.FC<
  FormFieldProps & { id: string }
  // eslint-disable-next-line complexity
> = ({
  disabled,
  id,
  label,
  description,
  children,
  required,
  errorId,
  className,
  containerClassName,
  error,
  edit = true,
  displayErrorMessage = true,
}) => {
  const formFieldLabel = label && (
    <FormFieldLabel
      content={label}
      error={error}
      disabled={disabled}
      required={required}
      edit={edit}
      id={id}
      className={cxStyle.label}
    />
  );

  const formFieldDescription = description && (
    <FormFieldDescription disabled={disabled}>{description}</FormFieldDescription>
  );

  return (
    <div className={className}>
      {edit ? (
        <>
          {formFieldLabel}
          {formFieldDescription}
          <FormFieldContainer className={containerClassName} disabled={disabled} error={error}>
            {children}
          </FormFieldContainer>
        </>
      ) : (
        <>
          {formFieldLabel}
          {formFieldDescription}
          {children}
        </>
      )}
      {error && edit && displayErrorMessage && (
        <FormFieldError id={errorId} className="pt-1">
          {error}
        </FormFieldError>
      )}
    </div>
  );
};

const childrenContainerStyle = {
  align: {
    center: "top-1/2 -translate-y-1/2",
    bottom: "bottom-2",
  },
};

/**
 * Container component for the children of the FormField component to vertically center the children
 * of `FormField`.

* @example
 * ```tsx
 * import { FormFieldChildrenContainer } from "./FormField";
 *
 * const MyForm = () => {
 *   return (
 *     <FormFieldInput>
 *       <FormFieldChildrenContainer>
 *         <CharacterCounter currentCount={currentCount} max={max} />
 *       </FormFieldChildrenContainer>
 *    </FormFieldInput>
 *   );
 * };
 * ```
 *
 * @returns The rendered FormFieldChildrenContainer component.
 *
 */
export const FormFieldChildrenContainer: FC<
  PropsWithChildren<{ align: keyof typeof childrenContainerStyle.align }>
> = ({ children, align }) => (
  <div className={cx("text-xs absolute right-3", childrenContainerStyle.align[align])}>{children}</div>
);
