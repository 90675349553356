import { NonIndexRouteObject, Outlet, RouteObject } from "react-router-dom";
import { FC, ReactNode, Suspense, lazy } from "react";
import { PermissionActionVO } from "@libs/api/generated-api";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { filterMap } from "@libs/utils/array";
import { RoleGuardLock } from "components/Main/RoleGuard";
import { PermissionAction } from "components/Roles/constants";

type Permission = {
  action: PermissionAction;
  domain: PermissionActionVO["domain"];
};

type ArchyNonIndexRouteObject = Omit<NonIndexRouteObject, "children" | "element" | "Component"> & {
  permission?: Permission;
  loadComponent?: () => Promise<FC>;
  children?: undefined;
};

type ArchyNonIndexWithChildrenRouteObject = Omit<
  NonIndexRouteObject,
  "children" | "element" | "Component"
> & {
  permission?: Permission;
  loadComponent: () => Promise<FC<{ children?: ReactNode }>>;
  children: ArchyRouteObject[];
};

type ArchyRouteObject = ArchyNonIndexRouteObject | ArchyNonIndexWithChildrenRouteObject | undefined;

const mapArchyRoute = ({
  loadComponent,
  permission,
  ...routeConfig
}: Exclude<ArchyRouteObject, undefined>): RouteObject => {
  if (loadComponent || permission || routeConfig.children) {
    return {
      ...routeConfig,
      children: routeConfig.children ? mapRoutes(routeConfig.children) : undefined,
      lazy() {
        // If a route object defines children routes take care of
        // suspense and placing an outlet.
        const outlet = routeConfig.children ? (
          <Suspense fallback={<LoadingContent />}>
            <Outlet />
          </Suspense>
        ) : null;

        let component: JSX.Element | null = null;

        if (loadComponent) {
          // Prefetch the component code and provide a lazy component for
          // react router to return immediately to not block navigation
          // while the component is loading.
          // Because the component is lazy Suspense will handle showing a loading
          // indicator
          loadComponent();

          const Component = lazy(() => loadComponent().then((mod) => ({ default: mod })));

          component = <Component>{outlet}</Component>;
        }

        // react router requires a promise to be returned even when nothing async happens
        return Promise.resolve({
          element: permission ? <RoleGuardLock {...permission}>{component}</RoleGuardLock> : component,
        });
      },
    };
  }

  return routeConfig;
};

export const mapRoutes = (routes: ArchyRouteObject[]): RouteObject[] => {
  return filterMap(routes, (route) => {
    if (route) {
      return mapArchyRoute(route);
    }

    return route;
  });
};
